import { useEffect } from "react";

import { EVENT_ERROR } from "@shared/constants";

import DataLayer from "@client/classes/data-layer/data-layer";
import LocalizeLinks from "@client/components/layouts/components/localize-links";

import Main from "@ui/js/components/main";

type Props = {
  error: Error & { digest?: string };
  reset?: () => void;
};

export default function ErrorPage({ error }: Props) {
  useEffect(() => {
    DataLayer.events.trigger(EVENT_ERROR, {
      message: error.message,
      object: "page",
      type: error.name,
    });
  }, [error]);

  return (
    <>
      <Main pageName="error">
        <div className="container">
          <div className="col-span-12 md:col-span-8 md:col-start-2 lg:col-span-6 lg:col-start-4">
            <div className="error-message">
              <h1 className="error-message__title">
                An Unexpected Error Occurred
              </h1>
              <div className="error-message__text">
                We're sorry for the inconvenience. Our team has been notified
                and is addressing the issue. Please refresh or try again later.
                Thank you for your patience.
              </div>
            </div>
          </div>
        </div>
      </Main>
      <LocalizeLinks />
    </>
  );
}
