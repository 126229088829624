import Script from "next/script";

/**
 * By default Next.js will render the "error" and "not-found" pages using the `DEFAULT_JOURNEY`. This script
 * determines whether the links on the page should be prefixed with `DEFAULT_JOURNEY` or something else. If
 * necessary, it replaces `DEFAULT_JOURNEY` with the correct prefix.
 */
export default function LocalizeLinks() {
  return (
    <Script id="layout-localize-links">
      {`const defaultDirectory = document.querySelector("a") ? new URL(document.querySelector("a")?.href).pathname.split("/")[1] : false;
        const currentDirectory = window.location.pathname.split("/")[1];
        if (defaultDirectory && currentDirectory && defaultDirectory !== currentDirectory) {
          document.querySelectorAll("a").forEach(($a) => $a.href = $a.href.replace(defaultDirectory, currentDirectory));
        }`}
    </Script>
  );
}
