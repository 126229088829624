import ErrorPage from "@client/pages/error";

type Props = {
  error: Error & { digest?: string };
  reset: () => void;
};

export default function Error(props: Props) {
  return <ErrorPage {...props} />;
}
